import React from 'react';

import { Customer, GranularPermissions, PermissionSets, User, UserInterface } from 'types/user';

export const templateUser: User = {
  userEmail: '',
  name: '',
  firstName: '',
  lastName: '',
  activeCustomer: null,
  isVerified: false,
  overrideActive: false,
  impersonateMode: false,
  permissionSet: {
    name: PermissionSets.BASIC,
    permissions: [GranularPermissions.BATCHES],
    isRestricted: false,
  },
  active: true,
  flags: {
    showFundingReport: false,
    showIntegrations: false,
    showBenefitsAdministration: false,
    showHiringAndOnBoarding: false,
    showTimeAndAttendance: false,
    showOnlineStore: false,
    showBillPay: false,
    showAccountSettings: false,
    showPermissionOverride: false,
    showSGASimulator: false,
    showCTACard: false,
    showImpersonateUser: false,
    showStatementReport: false,
    showMyEquipment: false,
    showConfiguration: false,
  },
  totalItemsInCart: 0,
  activeCustomerInformation: {
    customerHasActivePaymentsManagerPlus: true,
    customerHasActivePayrollPlus: true,
    customerTerminated: true,
    customerEligibleForAccountUpdater: false,
    isAddToCartAllowed: true,
    paymentsCustomerType: null,
    ownerEmails: ['test@test.com'],
    highRisk: false,
    city: 'test',
    dbaName: 'test',
    state: 'test',
    isValidCartDbaState: false,
    isValidCartDbaStatePr: false,
    isValidCartLegalState: false,
    isValidDbaAddress: false,
    isValidLegalAddress: false,
    mcc: 1234,
    forbiddenPaths: [],
    supportContact: {
      phone: '+18005551234',
      ext: '1234',
    },
  },
  userHasActivePaymentsAccounts: false,
  userHasActivePayrollAccounts: false,
  assignedCustomerApplications: [],
  callToActions: {
    addNewUser: true,
  },
  isMyAccountEligible: true,
};

export const templateSelectedCustomer: Customer = {
  customerId: {
    idType: '',
    idValue: '',
  },
  dbaName: '',
  state: '',
  city: '',
};

export const UserContext = React.createContext<UserInterface>({
  ...templateUser,
  selectedCustomer: templateSelectedCustomer,
  selectActiveCustomer: () => {},
  setActiveCustomer: () => {},
  getImpersonateUser: () => {},
  logout: () => {},
});
